<template>
  <div class="upload-box">
    <el-upload
        action="#"
        ref="upload"
        list-type="picture-card"
        :http-request="UploadImage"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :before-upload="beforeAvatarUpload"
        :limit="10"
        :on-change="handleChange"
        :on-exceed="handleExceed"
        :file-list="formData.fileList"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

  </div>
</template>


<script  name="UploadImg">

import axios from "axios";

export default {
  data(){
    return {
      formData: {
        name: "",
        brand: "",
        unit: "",
        category: [],
        desc: "",
        fileList: [],
        img: [],
      },
      dialogVisible:false,
      isname: true,
      dialogImageUrl: '',
      videoFlag: false,
      uploadFileUrl:  "/admin/sys-file/upload", // 上传的图片服务器地址


    }
  },
  mounted(){
    this.$refs.upload.clearFiles();
      // this.fileList = []
    // console.log( 222);
  },
  methods:{
    //删除图片
    handleRemove(file, fileList) {
      //   判断是否是正确格式图片才能执行删除
      if (file && file.status === "success") {
        let Pics = this.formData.fileList;
        Pics.forEach((item, index) => {
          if (file.name == item.name) {
            Pics.splice(index, 1);
          }
        });
      }
      //   console.log(this.formData.fileList);
    },
    //查看图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange(file, fileList) {
      //   console.log(fileList);
      //   console.log(file);
    },
    //   手动上传
    //   submitUpload() {
    //     console.log(this.$refs.upload)
    //     this.$refs.upload.submit();
    //   },
    // 在使用http-request方法的时候，调用的事件必须以param为参数，通过param.file取得文件。
    UploadImage(param) {

      const formData = new FormData();
      formData.append("file", param.file);
      let config = {
        method: "post",
        url: "/api/admin/sys-file/upload",
        headers: {
          'Authorization': 'Bearer '+  localStorage.getItem('access_token'),
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };
      axios(config).then((response) => {
        this.dialogImageUrl = response.data.data.url;
            this.formData.fileList.push({
              name: param.file.name,
              url: response.data.data.url,
              uid: param.file.uid,
            });
            param.onSuccess();
            this.$emit('childUpload', this.formData.fileList);
            this.$emit('change', this.formData.fileList);
          })
          .catch((response) => {
            param.onError();
          });
    },
    // 上传检测
    beforeAvatarUpload(file) {
      const type = ['image/jpeg','image/jpg', 'image/png','image/bmp']
      const isJPG = type.indexOf(file.type)  >= 0;
      const isLt2M = file.size / 1024 / 1024 < 2;
      this.isname = true;
      this.formData.fileList.forEach((item, index) => {
        if (file.name === item.name) {
          this.isname = false;
        }
      });
      if (!this.isname) {
        this.$message.error("图片重复");
      }
      if (!isJPG) {
        this.$message.error("图片只能是 JPG 格式!");
        // this.$refs.upload.handleRemove(file);
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
        // this.$refs.upload.handleRemove(file);
      }
      return isJPG && isLt2M && this.isname;
    },
    //控制上传数量提示
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传10个文件`);
    },

  }
}

</script>
<style scoped lang="scss">
.is-error {
  .upload {
    :deep(.el-upload),
    :deep(.el-upload-dragger) {
      border: 1px dashed var(--el-color-danger) !important;
      &:hover {
        border-color: var(--el-color-primary) !important;
      }
    }
  }
}
:deep(.disabled) {
  .el-upload,
  .el-upload-dragger {
    cursor: not-allowed !important;
    background: var(--el-disabled-bg-color);
    border: 1px dashed var(--el-border-color-darker) !important;
    &:hover {
      border: 1px dashed var(--el-border-color-darker) !important;
    }
  }
}
.upload-box {
  .no-border {
    :deep(.el-upload) {
      border: none !important;
    }
  }
  :deep(.upload) {
    .el-upload {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: v-bind(width);
      height: v-bind(height);
      overflow: hidden;
      border: 1px dashed var(--el-border-color-darker);
      border-radius: v-bind(borderRadius);
      transition: var(--el-transition-duration-fast);
      &:hover {
        border-color: var(--el-color-primary);
        .upload-handle {
          opacity: 1;
        }
      }
      .el-upload-dragger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;
        overflow: hidden;
        background-color: transparent;
        border: 1px dashed var(--el-border-color-darker);
        border-radius: v-bind(borderRadius);
        &:hover {
          border: 1px dashed var(--el-color-primary);
        }
      }
      .el-upload-dragger.is-dragover {
        background-color: var(--el-color-primary-light-9);
        border: 2px dashed var(--el-color-primary) !important;
      }
      .upload-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .upload-empty {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 30px;
        color: var(--el-color-info);
        .el-icon {
          font-size: 28px;
          color: var(--el-text-color-secondary);
        }
      }
      .upload-handle {
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: rgb(0 0 0 / 60%);
        opacity: 0;
        transition: var(--el-transition-duration-fast);
        .handle-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 6%;
          color: aliceblue;
          .el-icon {
            margin-bottom: 40%;
            font-size: 130%;
            line-height: 130%;
          }
          span {
            font-size: 85%;
            line-height: 85%;
          }
        }
      }
    }
  }
  .el-upload__tip {
    line-height: 18px;
    text-align: center;
  }
}
</style>
