import { render, staticRenderFns } from "./Image.vue?vue&type=template&id=27c07afc&scoped=true&"
import script from "./Image.vue?vue&type=script&name=UploadImg&lang=js&"
export * from "./Image.vue?vue&type=script&name=UploadImg&lang=js&"
import style0 from "./Image.vue?vue&type=style&index=0&id=27c07afc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c07afc",
  null
  
)

export default component.exports